.error_404 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 5em;
    line-height: 1.25em;
    font-family: $extra_condensed_font;
    color: $cyan;
    margin: 0;
  }

  h2 {
    font-size: 2em;
    font-family: $regular_font;
    margin: 0;
  }
}