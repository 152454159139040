$soundColor: $white;

#soundButton {
    padding: 2px;
    cursor: pointer;
    z-index: 8;
    margin: 0 0 0 0.4em;
    width: 2.6em;
    height: 100%;
    border-radius: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;


    &.light {
        .soundBars {
            div {
                &::after {
                    background-color: $white;
                }
            }
        }
    }

    // transparent pixel for IE clickability
    background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

    .no-touch &:hover {
        #soundBars {
            div {
                &::after {
                    background: $blue;
                }
            }
        }
    }

    .soundBars {
        display: flex;
        width: 1em;
        height: 1em;
        position: relative;

        transform: translateZ(101px);

        div {
            position: relative;
            height: 100%;
            flex: 1;

            &::after {
                background: $blue;
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 25%;
                width: 50%;
                height: 100%;

                transform-origin: 0 100%;
                transform: scaleY(10%);

                animation-name: soundBar;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }

            &:nth-child(0n + 1) {
                &::after {
                    animation-duration: 0.5s;
                }
            }

            &:nth-child(2) {
                &::after {
                    animation-duration: 0.54s;
                }
            }

            &:nth-child(3) {
                &::after {
                    animation-duration: 0.47s;
                }
            }

            &:nth-child(4) {
                &::after {
                    animation-duration: 0.46s;
                }
            }

            &:nth-child(5) {
                &::after {
                    animation-duration: 0.53s;
                }
            }
        }
    }

    &.on {
        .soundBars {
            div {
                &::after {
                    animation-name: soundBar;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                }
            }
        }
    }

    &.off {
        .soundBars {
            div {
                &::after {
                    animation-name: soundBarOff;
                    animation-iteration-count: 1;
                    animation-direction: alternate;
                }
            }
        }
    }
}

@-webkit-keyframes soundBarOff {
    100% {
        transform: scaleY(0.1);
        // height: 10%;
    }
}

@-webkit-keyframes soundBar {
    0% {
        transform: scaleY(0.01);
        // height: 1%;
    }

    100% {
        transform: scaleY(1);
        // height: 100%;
    }
}



@-moz-keyframes soundBarOff {
    100% {
        transform: scaleY(0.1);
        // height: 10%;
    }
}

@-moz-keyframes soundBar {
    0% {
        transform: scaleY(0.01);
        // height: 1%;
    }

    100% {
        transform: scaleY(1);
        // height: 100%;
    }
}



@keyframes soundBarOff {
    100% {
        transform: scaleY(0.1);
        // height: 10%;
    }
}

@keyframes soundBar {
    0% {
        transform: scaleY(0.01);
        // height: 1%;
    }

    100% {
        transform: scaleY(1);
        // height: 100%;
    }
}


