#selfie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  color: $magenta;

  .back_to_museum {
    opacity: 0;
    transition: opacity 1s;
    pointer-events: none;

    .circletext {
      fill: $blue;
    }

    .xicon {
      stroke: $magenta;
    }

    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $yellow;
    z-index: 1;
    transform: translateY(-100%) translateZ(1px);
  }

  .masker {
    position: relative;
    overflow: hidden;
  
    .maskee {
      display: inline-block;
      transform: translateY(-100%) translateZ(2px);
      transition-duration: 0;
    }
  }

  .instructions {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 0;
    padding-bottom: 10em;
    opacity: 0;
    transition: opacity 1s;

    p {
      color: $white;
      letter-spacing: 0.225em;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    transform: translateZ(1px);
    font-size: 1em;

    .are_you_ready {
      position: absolute;
      bottom: 100%;

      p {
        font-size: 2.4em;
        margin: 0;
        letter-spacing: 0.225em;
        white-space: nowrap;
        opacity: 0;
      }
    }

    .divine {
      font-family: $extra_condensed_font;
      font-size: 8.89em;
      margin: 0;
      line-height: 1em;

      &:nth-child(2) {
        letter-spacing: 0.01em;

        &::after {
          content: "DIVIN";
          display: inline-block;
          transform: translateY(-100%) scaleX(-1);
          margin-left: 0.025em;
          -webkit-text-stroke: 1px $magenta;
          -webkit-text-fill-color: transparent;
        }
      }

      &:nth-child(3) {
        margin-top: -0.2em;
      }
    }
  }

  &.showinstructions {
    .instructions {
      opacity: 1;
    }
  }

  &.showintro {
    $start_delay: 0.5s;

    &::before {
      transform: translateY(0%) translateZ(1px);
      transition: transform 0.5s ease-out;
    }

    .masker {
      &::after {
        transform: translateY(0%) translateZ(2px) scaleX(-1) !important;
        transition: transform 0.5s ease-in-out $start_delay + 0.85s;
      }

      .maskee {
        transform: translateY(0%) translateZ(2px);
        transition: transform 0.5s ease-in-out $start_delay + 0.85s;        
      }

      &:nth-child(2) {
        &::after {
          transition-delay: $start_delay + 0.85s;
          transition-duration: 0.5s;
          transition-timing-function: ease-out;
        }
  
        .maskee {
          transition-delay: $start_delay + 0.85s;
          transition-duration: 0.5s;
          transition-timing-function: ease-out;
        }
      }
    }

    .container {
      .are_you_ready {
        p {
          animation: fadeReady;
          animation-duration: 0.75s;
          animation-timing-function: linear;

          $delay: 0.06s;

          &:nth-child(1) {
            animation-delay: $start_delay + $delay;
          }
  
          &:nth-child(2) {
            animation-delay: $start_delay + $delay * 2;
          }
  
          &:nth-child(3) {
            animation-delay: $start_delay + $delay * 3;
          }
  
          &:nth-child(4) {
            animation-delay: $start_delay + $delay * 4;
          }

          &:nth-child(5) {
            animation-delay: $start_delay + $delay * 5;
          }

          &:nth-child(6) {
            animation-delay: $start_delay + $delay * 6;
          }

          &:nth-child(7) {
            animation-delay: $start_delay + $delay * 7;
          }

          &:nth-child(8) {
            animation-delay: $start_delay + $delay * 8;
          }

          &:nth-child(9) {
            animation: none;
            opacity: 1;
            transition: opacity 0s $start_delay + $delay * 9;
          }
        }
      }
    }
  }

  &.hideintro {
    &::before {
      transform: translateY(100%) translateZ(1px);
      transition: transform 0.5s ease-in 0.5s;
    }

    .masker {
      &::after {
        transform: translateY(100%) translateZ(2px) scaleX(-1) !important;
        transition: transform 0.4s ease-in 0s !important;
      }

      .maskee {
        transform: translateY(100%) translateZ(2px);
        transition: transform 0.4s ease-in 0s !important;        
      }
    }

    .container {
      .are_you_ready {
        p {
          &:nth-child(9) {
            opacity: 0;
            transition: opacity 0.125s;
          }
        }
      }
    }
  }
}

@keyframes fadeReady {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}