$numquotes: 6;
$quoteDuration: 4s;
$duration: 0.1666666667; // (1 / $numquotes)
$perc: ($duration * 100) * 0.25;
$showdelay: 1s;

#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 12;
  background: $blue;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  // transform: translateZ(10px);

  .tt {
    display: block;
    width: 188px;
  }

  .top_wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-basis: 35%;
    justify-content: flex-end;
  }

  .masker {
    position: relative;
    overflow: hidden;

    .maskee {
      transform: translateY(100%);
      // transition: transform 0.75s ease-out $showdelay + 1s;
      transition: none;
    }
  }

  .cages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .cage {
      display: block;
      position: relative;
      fill: $cyan;
      width: 75%;
      max-width: 75%;

      opacity: 0;
    }
  }

  &.hide {
    opacity: 0;
    transition: opacity 1s;
    pointer-events: none;
  }

  &.show {
    .masker {
      .maskee {
        transform: translateY(0%);
        transition: transform 0.75s ease-out $showdelay + 1s;
      }
    }

    .loading_circle {
      opacity: 1;
      transition: opacity 0.5s $showdelay + 1.5s;

      svg {
        transform: scale(1) rotate(0deg);
        transition: transform 1s ease-in-out $showdelay + 1.75s;
      }
  
      #loaderText {
        transform: scale(1) rotate(0deg);
        transition: transform 1s ease-in-out $showdelay + 1.75s;
      }
    }

    .quotes_module {
      opacity: 1;
      transition: opacity 1.5s;
      transition-delay: $showdelay + 1.75s;
    }

    .cages {
      padding: 2em 0 2em;

      .cage {
        animation: fadeCage;
        animation-duration: 1s;
        animation-timing-function: linear;

        $delay: 0.06666s;

        &:nth-child(1) {
          animation-delay: $showdelay + $delay * 4;
        }

        &:nth-child(2) {
          animation-delay: $showdelay + $delay * 3;
        }

        &:nth-child(3) {
          animation-delay: $showdelay + $delay * 2;
        }

        &:nth-child(4) {
          animation-delay: $showdelay + $delay;
        }
      }
    }
  }

  &.complete {

    // .loading_circle {
    //   opacity: 1;
    //   transition: opacity 0.5s $showdelay + 1.5s;

    //   svg {
    //     transform: scale(1) rotate(0deg);
    //     transition: transform 1s ease-in-out $showdelay + 1.75s;
    //   }
  
    //   #loaderText {
    //     transform: scale(1) rotate(0deg);
    //     transition: transform 1s ease-in-out $showdelay + 1.75s;
    //   }
    // }
    
    .loading_circle {
      svg {
        opacity: 0;
        transform: scale(0) rotate(-90deg);
        // transition: opacity 0.25s;
        transition: opacity 0.25s 0.75s, transform 0.5s ease-in-out 0s;
      }

      #loaderText {
        opacity: 0;
        transform: scale(0) rotate(-90deg);
        transition: opacity 0.25s 0.75s, transform 0.5s ease-in-out 0s;
      }

      .shadowbutton {
        opacity: 1;
        transition: opacity 0.5s 0.75s;
        pointer-events: all;
      }
    }

    &.hide {
      pointer-events: none;
      .loading_circle {
        pointer-events: none;
        .shadowbutton {
          pointer-events: none;
        }
      }
    }
  }

  .loading_circle {
    position: relative;
    width: 12em;
    height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
    opacity: 0;
    // font-size: 1em;

    .shadowbutton {
      font-family: $condensed_font;
      font-size: 2em;
      padding: 0.2em 2em 0em;
      line-height: 2.25em;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: auto;
      margin: 0;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(0) rotate(-90deg);

      .circletext {
        font-size: 0.675em;
        letter-spacing: 0.23em;
      }
    }

    #loaderText {
      font-family: $condensed_font;
      font-size: 2.6em;
      margin: 0;
      transform: scale(0) rotate(-90deg);
    }
  }

  h1 {
    font-size: 1.8em;
    font-family: $condensed_font;

    &.masker {
      .maskee {
        transition-duration: 0.5s;
        transition-delay: $showdelay + 1.25s;
      }
    }
  }

  .cagemasker {
    width: 300px;
    // max-width: 68%;

    svg {
      fill: $white;
      width: 100%;
    }
  }

  .quotes_module {
    // flex-basis: 25%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    opacity: 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;

      li {
        grid-row: 1;
        grid-column: 1;
        opacity: 0;
        animation: quotes;
        animation-duration: $quoteDuration * $numquotes;
        animation-iteration-count: infinite;

        &:nth-child(1) {
          animation-delay: 0;
        }

        &:nth-child(2) {
          animation-delay: $quoteDuration;
        }

        &:nth-child(3) {
          animation-delay: $quoteDuration * 2;
        }

        &:nth-child(4) {
          animation-delay: $quoteDuration * 3;
        }

        &:nth-child(5) {
          animation-delay: $quoteDuration * 4;
        }

        &:nth-child(6) {
          animation-delay: $quoteDuration * 5;
        }

        &.current_quote {
          opacity: 1;
        }

        p:first-child {
          font-family: $condensed_font;
          text-transform: uppercase;
          font-size: 2.4em;
          margin: 0;
        }

        p:last-child {
          color: $cyan;
          margin: 0.5em 0 0;
          font-size: 1.4em;

          &::before {
            content: "– "
          }
        }
      }
    }
  }
}

@keyframes fadeCage {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes quotes {
  0% {
    opacity: 0;
  }

  #{$perc * 2}% {
    opacity: 1;
  }

  #{$perc * 4}% {
    opacity: 1;
  }

  #{$perc * 5}% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}