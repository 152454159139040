#mainHeader {
  // display: none;

  &.show {
    display: block;
  }

  --capabilities_lineheight: 1.75em;

  position: fixed;
  width: 100%;
  height: 100%;

  // flex-flow: column;
  // justify-content: center;
  // padding-left: 4.6em;
  top: 0;
  left: 0;
  // transform: translateZ(1px);
  z-index: 10;
  pointer-events: none;
  color: $blue;

  .top_bar {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    align-items: center;

    .menu_toggle {
      position: relative;
    }
  }

  .menu_overlay {
    opacity: 0;
  }

  &.open {
    pointer-events: all;

    .menu_overlay {
      display: flex;
      flex-flow: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: 0;
      opacity: 1;
    }

    ul {
      opacity: 1;
      transition: opacity 0.5s $ease_in_out;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        ul {
          opacity: 1;
          height: auto;
          max-height: calc(var(--num_capabilities) * var(--capabilities_lineheight)); // set the variable of number of capabilities in base.njk with eleventy and multiply it by the line-height to do this all in css
          transition: opacity 1s;
          overflow: hidden;
          margin: 0.15em 0 0.5em;

          @media (min-width: 429px) {
            max-height: calc(var(--num_capabilities) * var(--capabilities_lineheight));
            // margin: 0;
          }
        }
      }
    }

    .menu_toggle {
      .close_button {
        opacity: 1;
        pointer-events: all;
      }
  
      .open_button {
        pointer-events: none;
        opacity: 0;
      }
    }

    .top_bar {
      h1 {
        color: $blue;
      }

      .menu_toggle {
        button {
          svg {
            stroke: $blue;
          }
        }
      }
    }
  }

  &.close {
    pointer-events: none;

    .menu_overlay {
      opacity: 0;
    }

    ul {
      opacity: 0;
      // transform: translate3d(0, -2rem, 2px);
      transition-duration: 0.25s;

      li {
        ul {
          max-height: 0;
          opacity: 0;
          transition-duration: 0.25s;
        }
      }
    }

    .top_bar {
      h1 {
        color: $white;
      }

      .menu_toggle {
        button {
          svg {
            stroke: $white;
          }
        }

        .open_button {
          pointer-events: all;
          opacity: 1;
        }
    
        .close_button {
          pointer-events: none;
          opacity: 0;
        }
      }
    }
  }

  .top_bar {
    top: 1em;
    left: 1em;
  }

  #social {
    bottom: 1em;
    left: 0em;
    padding: 0;
  }

  h1 {
    color: $white;
    margin: 0 0 0 1em;
  }

  .menu_toggle {
    width: 2.75em;
    height: 2.75em;
    z-index: 0;
    transform: translateZ(1px);
    pointer-events: all;
    transition: opacity 0.5s $ease_in_out 1s;

    button {
      width: 100%;
      height: 100%;
      position: absolute;

      &.close_button {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  #cameraswitcher {
    display: block;
    padding: 0;
    margin: 0;
    position: fixed;
    top: 6em;
    right: 0;
    background: $yellow;
    border: none;
    width: 5em;
    height: 5em;
    pointer-events: all;
    cursor: pointer;

    * {
      pointer-events: none;
    }
    
    svg {
      fill: $blue;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.65);

      &.camera-switcher-icon,
      &.camera-lens-icon {
        fill: $yellow;
      }

      &.camera-switcher-icon {
        display: block;
      }

      &.camera-lens-icon {
        display: none;
      }

      // .desktop &.camera-switcher-icon {
      //   display: none;
      // }

      // .desktop &.camera-lens-icon {
      //   display: block;
      // }
    }
  }

  #social {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    position: absolute;
    left: 0;
    bottom: 8em;
    z-index: 1;
    margin: 0;
    list-style: none;

    li {
      margin: 0.25em 0;

      a {
        display: block;
        pointer-events: all;
        width: 3em;
        height: 3em;
        position: relative;

        * {
          pointer-events: none;
        }

        &::after {
          display: none;
        }

        // border-radius: 100%;
        // border: solid 2px $magenta;
        background: $yellow;
      }

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
        fill: $blue;
        display: block;
        transform: scale(0.8);
      }

      .facebook {
        svg {
          transform: scale(0.85);
        }
      }

      .instagram {
        svg {
          transform: scale(0.7);
        }
      }
    }
  }
  
  a {
    color: $blue;
    // display: block;
  
    &.selected {
      pointer-events: none;
    }
  }

  nav {
    position: absolute;
    z-index: 0;
    transform: translateZ(1px);
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    --top: calc(#{linearSizeWidth(16, 50, 429, 1600)} + 1.3rem);
    top: var(--top);
    height: calc(100% - var(--top) * 2);

    left: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)} * 1.75 + #{linearSizeWidth(44, 50, 429, 1600)});

    font-size: linearSizeWidth(22, 28, 320, 375);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      opacity: 0;
      // transform: translate3d(0, -2rem, 2px);

      li {
        font-size: 1em;
        line-height: 1em;

        a {          
          font-weight: $bold;
          line-height: 1.5em;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            background: $blue;
            top: 1em;
            height: 3px;
            transform-origin: 0 0;
            transform: scaleX(0);
            transition: transform 0.25s $ease_in;
          }

          &.selected,
          .no-touch &:hover {
            &::after {
              transition: transform 0.25s $ease_out;
              transform: scaleX(1);
            }
          }
        }
    
        ul {
          // font-size: 1.5em;
          line-height: 1.25em;
          font-weight: $normal;
          font-size: 0.6428571429em;
          height: 0;
          max-height: 0;
          opacity: 0;

          li {
            font-size: 1em;

            a {
              font-size: 1em;
              line-height: var(--capabilities_lineheight);
              font-weight: $normal;
    
              &.selected {
                // text-decoration: underline;
              }

              &::after {
                top: 1.3em;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 429px) {
    .top_bar {
      top: linearSizeWidth(16, 50, 429, 1600);
      left: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)});
    }

    #social {
      // bottom: linearSizeWidth(80, 160, 429, 1600);
    }

    h1 {
      margin-left: calc((env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)}) * 0.65);
    }

    .menu_toggle {
      width: linearSizeWidth(44, 50, 429, 1600);
      height: linearSizeWidth(44, 50, 429, 1600);
    }
  }

  @media (orientation: portrait) {
    nav {
      .mobile & {
        height: calc(100% - var(--top) * 5);
      }
    }
  }

  @media (max-height: 600px) and (orientation: landscape) {
    nav {
      font-size: linearSizeHeight(20, 28, 520, 600);
    }
  }

  @media (max-height: 428px) and (orientation: landscape) {
    height: 100%;

    nav {
      width: auto;
      height: calc(100% - var(--top) * 1.2);
      font-size: linearSizeHeight(22, 30, 320, 414);

      &>ul {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;

        &>li {
          ul {
            position: absolute;
            left: calc(100% + 1em);
            top: 50%;
            // transform: translateY(-50%) !important;
            padding-left: 1em;
            border-left: solid 1px $white;
            height: auto;
            max-height: none;

            li {
              a {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

.menu_button {
  position: relative;
  margin: 0;
  display: block;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke-width: max(4px, 0.15em);
    pointer-events: none;
    transform: scale(0.75);
    stroke: $white;
  }
}