/* autoprefixer grid: on */
//
@import './includes/variables';

html {
    font-size: 10px;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    -ms-content-zooming: none; /* Disables zooming */
    color: $white;
    font-family: $regular_font;
    font-weight: $normal;
    font-size: min(#{linearSizeWidth(8, 10, 320, 428)}, #{linearSizeHeight(6, 10, 320, 428)});
    -webkit-marquee-increment: 1vw; // Safari bug fix https://www.sarasoueidan.com/blog/safari-fluid-typography-bug-fix/
    line-height: 1.25em;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background: $blue;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    -webkit-text-size-adjust: 100%;
    -webkit-touch-callout: none;
    
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    touch-action: pan-x pan-y;

    .cssloaded & {
        opacity: 1;
        transition: opacity 0.75s ease-in-out 0.25s;
    }

    &[data-section="Desktop"] {
        overflow: hidden;

        #camerafeed {
            opacity: 0;

            &.show {
                opacity: 1;
                transition: opacity 1s;
            }
        }
    }
}

/* Screen Reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}

a {
    color: $white;
    -webkit-user-drag: none;
    text-decoration: none;
}

p {
    font-size: 1.6em;
    line-height: 1.25em;
}

a:focus,
a:active,
button:focus,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
    line-height: 1em;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
    line-height: 1em;
    font-weight: $normal;
}

#shell {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.section {
}

.back_to_museum {
    position: fixed;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
}

.back_to_museum,
.loading_circle {
    background: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    font-size: 10px;

    svg {
        pointer-events: none;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 12em;
        height: 12em;

        --font: $regular_font;
    
        .circletext {
            fill: $cyan;
            font-size: 1.2em;
            font-family: $regular_font;
            letter-spacing: 0.25em;

            // .desktop & {
            //     letter-spacing: 0.25em;
            // }

            text-anchor: middle;
            transform-origin: 50%;

            animation: loadinganim 20s linear infinite;
            animation-direction: reverse;
        }
    }

    .xicon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0.25);
        stroke: $white;
        stroke-width: 0.75em;
    }
}

.fullwh {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.dg.ac {
    z-index: 100;
}

.shadowbutton {
    font-family: $wide_font;
    color: $blue;
    text-transform: uppercase;
    padding: 0;
    line-height: 2.5em;
    padding: 0.2em 1em 0em;
    font-size: 1.5em;
    display: block;
    margin: 2em 0;
    border: none;
    border-radius: 0;
    // appearance: none;
}

#art_vid {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    opacity: 0;
}

.masker {
    position: relative;
    overflow: hidden;

    .maskee {
        transform: translateY(100%);
        transition: transform 0.75s ease-out;
        display: block;
    }
}

@import './8thwall/8thwall';
@import './sections/ar';
@import './sections/selfie';
@import './sections/giftshop';
@import './sections/modelviewer';
@import './widgets/header';
@import './widgets/footer';
@import './widgets/sound_button';
@import './widgets/loader';
@import './sections/error_404';

@-ms-viewport {
    width: device-width;
}

@keyframes loadinganim {
    0% { 
        transform: rotate(0); 
    }
    100% { 
        transform: rotate(360deg);
    }
}