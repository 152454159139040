$modelviewer_border_thickness: 2em;

#ar {
  z-index: 1;
  pointer-events: none;

  h1 {
    pointer-events: all;
  }

  .back_to_museum {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s, transform 0.35s ease-out;
    transition-delay: 0s;
    transform: translateX(-50%) rotate(45deg) scale(0.5);
    top: 3em;

    .circletext {
      fill: $blue;
    }

    .xicon {
      stroke: $magenta;
    }
  
    &.show {
      opacity: 1;
      transform: translateX(-50%) rotate(0deg) scale(1);
      pointer-events: all;
    }
  }

  .maskee {
    transition-duration: 0.25s;
    transition-delay: 0s;
  }

  #art_info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: $modelviewer_border_thickness;
    left: 0;
    width: 100%;
    text-align: center;
    pointer-events: all;

    p {
      margin: 0;
      font-family: $condensed_font;
      font-size: 1em;

      &.artwork_by {
        font-size: 1em;
        letter-spacing: 0.2em;
        line-height: 1.5em;
        font-family: $regular_font;
      }
    }

    #titles {
      margin-bottom: 0.5em;
      display: flex;
      flex-flow: column;
      align-items: center;

      p {
        text-transform: uppercase;
        font-size: 1.5em;
        line-height: 1em;
        margin-bottom: 0.5em;
        max-width: 75%;
      }
    }

    #artist {
      margin-bottom: 2em;

      a {
        font-size: 2em;
        line-height: 1em;
        text-transform: uppercase;
      }
    }
  }

  /**
   * COLORS
   */
  .border_vertical,
  .border_horizontal {
    &::before,
    &::after {
      content: "";
      background: $cyan;
    }
  }

  #art_info {
    color: $cyan;

    a {
      color: $cyan;
    }
  }

  .back_to_museum {
    .circletext {
      fill: $cyan;
    }

    .xicon {
      stroke: $yellow;
    }
  }

  &.head {
    .border_vertical,
    .border_horizontal {
      &::before,
      &::after {
        content: "";
        background: $blue;
      }
    }

    #art_info {
      color: $blue;

      a {
        color: $blue;
      }
    }

    .back_to_museum {
      .circletext {
        fill: $blue;
      }

      .xicon {
        stroke: $magenta;
      }
    }
  }
  /**
   * END COLORS
   */

  &.modelviewer {

    .maskee {
      transform: translateY(0%);
      transition-duration: 0.25s;
      transition-delay: 1s;
    }

    .back_to_museum {
      opacity: 1;
      transform: translateX(-50%) rotate(0deg) scale(1);
      transition-delay: 0.5s;
      pointer-events: all;
    }

    .border_vertical,
    .border_horizontal {
      &::before,
      &::after {
        transform: scale(1, 1);
        transition-delay: 0.5s;
      }
    }
  }

  .border_vertical,
  .border_horizontal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      transition: transform 0.25s ease-in-out;
      width: $modelviewer_border_thickness;
      height: $modelviewer_border_thickness;
    }
  }

  .border_vertical {
    &::before,
    &::after {
      content: "";
      height: 100%;
      transform: scale(0, 1);
    }

    &::before {
      left: 0;
      transform-origin: 0% 0%;
    }
    
    &::after {
      right: 0;
      transform-origin: 100% 0%;
    }
  }

  .border_horizontal {
    &::before,
    &::after {
      content: "";
      width: 100%;
      transform: scale(1, 0);
    }

    &::before {
      top: 0;
      transform-origin: 0% 0%;
    }
    
    &::after {
      bottom: 0;
      transform-origin: 0% 100%;
    }
  }
}

// #close_model_viewer {
//   position: fixed;
//   left: 50%;
//   top: 3em;
//   font-size: 3em;
//   pointer-events: all;
// }

.reticle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 3.5em);
  padding: 3em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &::before,
    &::after {
      content: "";
      display: block;
      position: relative;
      width: 3em;
      height: 3em;
      border: solid 1px $yellow;
    }

    &::before {
      border-right-color: transparent;
    }

    &::after {
      border-left-color: transparent;
    }

    &:nth-child(1) {
      &::before,
      &::after {
        border-bottom-color: transparent;
      }
    }

    &:nth-child(2) {
      &::before,
      &::after {
        border-top-color: transparent;
      }
    }
  }
}

#findposter,
#tap_to_place {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
  z-index: 11;
  // transform: translateZ(1px);
}

#findposter {
  .findposter & {
    opacity: 1;
    // transition-delay: 1s;
  }

  p {
    font-size: 2.25em;
    margin: 1.25em 0 1em;
  }

  img {
    width: 150px;
    height: auto;
  }
}

#tap_to_place {
  .tap_to_place & {
    opacity: 1;
    transition-delay: 1s;
  }

  p {
    font-size: 2.25em;
    margin: 1.25em 0 1em;
  }

  img {
    width: 150px;
    height: auto;
  }
}