@font-face {
  font-family: "AcuminCondensedBlack";
  src: url('../assets/fonts/AcuminCondensedBlack/font.woff2') format('woff2'), url('../assets/fonts/AcuminCondensedBlack/font.woff') format('woff');
}
@font-face {
  font-family: "AcuminExtraCondensedBlack";
  src: url('../assets/fonts/AcuminExtraCondensedBlack/font.woff2') format('woff2'), url('../assets/fonts/AcuminExtraCondensedBlack/font.woff') format('woff');
}
@font-face {
  font-family: "AcuminWideBlack";
  src: url('../assets/fonts/AcuminWideBlack/font.woff2') format('woff2'), url('../assets/fonts/AcuminWideBlack/font.woff') format('woff');
}
@font-face {
  font-family: "AcuminBold";
  src: url('../assets/fonts/AcuminBold/font.woff2') format('woff2'), url('../assets/fonts/AcuminBold/font.woff') format('woff');
}

$regular_font: 'AcuminBold', sans-serif;
$condensed_font: 'AcuminCondensedBlack', sans-serif;
$extra_condensed_font: 'AcuminExtraCondensedBlack', sans-serif;
$wide_font: 'AcuminWideBlack', sans-serif;

$light: normal;
$normal: normal;
$bold: normal;