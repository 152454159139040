#modelviewer {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s 0.25s;
    // pointer-events: all;

    .close_button {
      pointer-events: all;
    }
  }

  .close_button {
    top: 1em;
    left: 1em;
    width: 2.75em;
    height: 2.75em;
    position: absolute;
    z-index: 1;
  }

  .get_tickets {
    font-family: $extra_condensed_font;
    background: transparent;
    color: $white;
    border: solid 2px $white;
    border-radius: 0px;
    text-transform: uppercase;
    padding: 0;
    line-height: 2em;
    padding: 0.15em 1em 0em;
    display: inline-block;
    font-size: 2em;
  }

  @media (min-width: 429px) {
    .close_button {
      top: linearSizeWidth(16, 50, 429, 1600);
      left: calc(env(safe-area-inset-left) * 0.5 + #{linearSizeWidth(16, 50, 429, 1600)});
      width: linearSizeWidth(44, 50, 429, 1600);
      height: linearSizeWidth(44, 50, 429, 1600);
    }
  }
}