#footer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 12;

  &.open {
    pointer-events: all;

    .bottom_bar {
      #legal_drawer {
        transform: translateY(0%);
      }
    }
  }

  .bottom_bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $yellow;
    color: $blue;
    pointer-events: all;
  
    padding: 0.5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    z-index: 30;
  
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: $yellow;
      z-index: 1;
    }
  
    #footer_links {
      display: flex;
      list-style: none;
      height: 2em;
      padding: 0;
      margin: 0;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;
      z-index: 1;
      
  
      li {
        a {
          color: $blue;
          text-decoration: underline;
          margin: 0.2em 0.5em 0em;
          display: block;
        }
  
        &.get_tickets {
          margin-left: auto;
  
          a {
            line-height: 2em;
            padding: 0.2em 1.25em 0;
            background: $blue;
            color: $yellow;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }
  
    #legal_drawer {
      position: absolute;
      background: $magenta;
      color: $yellow;
      bottom: 100%;
      width: 100%;
      left: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 3em 0;
      transform: translateY(100%);
      transition: transform 0.25s ease-in-out;
      z-index: 0;
  
      &.open {
        transform: translateY(0%);
      }
  
      img {
        margin-bottom: 2em;
        display: block;
        max-width: 90%;
        height: auto;
      }
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        width: 75%;
        line-height: 1.5em;
  
        li {
          display: inline-block;
  
          a {
            color: $yellow;
            text-decoration: underline;
  
            margin: 0 1em;
          }
        }
      }
    }
  }
}