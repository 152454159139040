#giftshop {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  background: $blue;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  .tt {
    display: block;
    width: 188px;
    margin: 1em auto 0em;
  }

  .desktop & {
    justify-content: center;

    .content_wrapper {
      margin-bottom: 0em;
    }
  }

  .release_date {
    font-family: $extra_condensed_font;
    color: $cyan;
    margin: 1em 0 0;
    font-size: 3.5em;
    line-height: 1em;
  }

  .get_tickets {
    margin-top: 1.25em;
  }

  .content_wrapper {
    width: 75%;
    position: relative;
    // margin-bottom: 3em;

    #thanks,
    #submitting {
      opacity: 0;
      pointer-events: none;
      width: 100%;
      height: 75%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h2 {
        color: $white;
        font-size: 3em;
        font-family: $extra_condensed_font;
      }
    }
  }

  &.success {
    .content_wrapper {
      #thanks {
        opacity: 1;
        transition: opacity 0.5s;
      }

      form {
        transition: opacity 0.5s;
        opacity: 0;
        pointer-events: none;

        .shadowbutton.enter {
          input[type="submit"] {
            pointer-events: none;
          }
        }
      }
    }
  }

  &.processing {
    .content_wrapper {
      #submitting {
        opacity: 1;
        transition: opacity 0.5s;
      }

      form {
        transition: opacity 0.5s;
        opacity: 0.25;
        pointer-events: none;

        .shadowbutton.enter {
          input[type="submit"] {
            pointer-events: none;
          }
        }
      }
    }
  }

  #general_error {
    display: none;
    color: $magenta;

    &.show {
      display: block;
    }
  }

  form {
    display: block;
    width: 100%;
    position: relative;

    input:not([type="checkbox"]) {
      display: block;
      width: 100%;
      padding: 0.275em 1.25em 0;
      margin: 0 0 0.5em;
      text-transform: uppercase;
      font-size: 1.6em;
      border-radius: 0;
    }

    label.checkbox {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 0.75em;

      input[type="checkbox"] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
        padding: 0;
        margin: 0;
        font-size: 1.6em;
        border-radius: 0;
        -webkit-appearance: none;
        appearance: none;
        margin-right: 0.75em;
  
        &::before {
          content: "×";
          padding-top: 0.15em;
          visibility: hidden;
        }

        &:checked {
          &::before {
            visibility: visible;
          }
        }
      }

      a {
        text-decoration: underline;
      }

      span {
        font-size: 1.1em;
        padding-top: 0.15em;
        text-align: left;
      }
    }

    .inputwrapper {
      .error_message {
        display: none;
        color: $magenta;
        margin: 1em 0 0.1em;
        font-size: 1.25em;
        line-height: 1em;
        text-align: left;
      }

      &.invalid {
        .error_message {
          display: block;
        }

        input:not([type="submit"]) {
          border-color: $magenta;
        }
      }
    }

    input:not([type="submit"]) {
      background: transparent;
      border: solid 1px $cyan;
      line-height: 2em;
      padding: 0.275em 1.25em 0;
      margin: 0 0 0.5em;
      color: $white;
      text-transform: uppercase;
      font-size: 1.6em;

      &::placeholder {
        color: $white;
      }
    }

    input[type="submit"] {
      background: transparent;
      border: none;
      line-height: inherit;
      padding: inherit;
      margin: 0;
      color: inherit;
      text-transform: uppercase;
      font-size: inherit;

      &::placeholder {
        color: $white;
      }
    }
  }

  .back_to_museum {
    background: transparent;
    border: none;
    position: fixed;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    // font-size: 3em;
  }

  h1 {
    font-family: $extra_condensed_font;
    font-size: 10em;
    color: $cyan;
    margin: 0;
    margin-bottom: -0.15em;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.5em;
  }

  transition: opacity 1s 0.25s;

  &.show {
    visibility: visible;
    opacity: 1;
    pointer-events: all;

    .back_to_museum {
      cursor: pointer;
      pointer-events: all;
    }

    .shadowbutton.enter {
      pointer-events: none;
  
      &::before {
        pointer-events: none;
      }
  
      input[type="submit"] {
        pointer-events: all;
      }
    }
  }
}